import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  ElectricMeter,
  MeterReading,
} from '../../../../../../../common/interfaces/prisma.binding';
import { FetchElectricMetersAction } from '../../../admin/electric-meters/actions/fetch-electric-meters.action';
import { ElectricMeterState } from '../../../admin/electric-meters/electric-meter.state';
import { ToolTimeSettingsState } from '../../../admin/tool-time-settings/tool-time-settings.state';
import { ReadingOnDateQuery } from './queries/reading-on-date.query';

@Component({
  selector: 'tt-meter-reading-widget',
  templateUrl: './meter-reading-widget.component.html',
  styleUrls: ['./meter-reading-widget.component.scss'],
})
export class MeterReadingWidgetComponent implements OnInit {
  @Select(ElectricMeterState.electricMeters) electricMeters$: Observable<
    ElectricMeter[]
  >;
  electricMeters = [];
  @Input()
  title = '';
  @Output()
  selectedElectricMeterReading = new EventEmitter<MeterReading>();

  electricMeterControl = new UntypedFormControl();
  filteredMeters: Observable<ElectricMeter[]>;
  meterReading = 0;
  currentKwhPrice;
  meterReadingReturnObject: Partial<MeterReading> = {};

  constructor(
    private store: Store,
    private readingOnDateQuery: ReadingOnDateQuery,
  ) {}

  async ngOnInit() {
    await this.store.dispatch(new FetchElectricMetersAction()).toPromise();

    this.electricMeters$.subscribe((electricMeters) => {
      this.electricMeters = electricMeters;
    });

    this.filteredMeters = this.electricMeterControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value)),
    );
    // if the meter input/autocomplete changes and a "real" meter is selected then emit object
    this.electricMeterControl.valueChanges.subscribe((electricMeter) => {
      if (typeof electricMeter === 'object') {
        this.meterReadingReturnObject.meter = electricMeter;
        this.emitMeterReading();
      } else {
        delete this.meterReadingReturnObject.meter;
      }
    });
    this.currentKwhPrice = this.store.selectSnapshot(
      ToolTimeSettingsState.kwhPrice,
    );
  }

  /**
   * if the meter reading input is changed
   */
  onMeterReadingInputChange() {
    if (this.meterReading !== null) {
      this.meterReadingReturnObject.meterReading = this.meterReading.toString();
    }
    this.emitMeterReading();
  }

  /**
   * if everything is set emit meterReading to parent
   */
  emitMeterReading() {
    if (
      (this.meterReadingReturnObject as object).hasOwnProperty(
        'meterReading',
      ) &&
      (this.meterReadingReturnObject as object).hasOwnProperty('meter')
    ) {
      if (parseFloat(this.meterReadingReturnObject.meterReading) >= 0) {
        this.meterReadingReturnObject.kwhPriceOnReading = this.currentKwhPrice;
        this.selectedElectricMeterReading.emit(
          this.meterReadingReturnObject as MeterReading,
        );
      }
    }
  }

  /**
   * this takes the object from the filter and return this value to show in the input
   * @param val
   */
  inputValue(val) {
    return val ? val.address : val;
  }

  onDateChange(event: MatDatepickerInputEvent<any>) {
    console.log('Data', new Date(event.value).toISOString());
    this.meterReadingReturnObject.entryDate = new Date(
      event.value,
    ).toISOString();
    this.emitMeterReading();
  }

  setMeterValue() {
    this.readingOnDateQuery
      .watch(
        {
          date: this.meterReadingReturnObject.entryDate.toString(),
          meterAddress: parseInt(
            this.meterReadingReturnObject.meter.address,
            10,
          ),
          gpioDeviceId: this.meterReadingReturnObject.meter.gpioDevice.deviceID,
          meterId: this.meterReadingReturnObject.meter.id,
        },
        {
          fetchPolicy: 'network-only',
        },
      )
      .valueChanges.subscribe(
        ({ data, loading }: { data: any; loading: boolean }) => {
          this.meterReading = Math.round(data.getFirstMeterReadingFromDate);
          this.onMeterReadingInputChange();
        },
      );
  }

  /**
   * Prevent the datepicker to select dates for tomorrow
   */
  dateNewerThenToday() {
    return new Date();
  }

  /**
   * this is the filter for the auto complete
   * @param value
   * @private
   */
  private _filter(value: string): ElectricMeter[] {
    if (value === undefined) {
      return this.electricMeters;
    }
    let filterValue = '';
    if (typeof value === 'object') {
      filterValue = (value as ElectricMeter).address.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }
    return this.electricMeters.filter((option) =>
      option.address.toLowerCase().includes(filterValue),
    );
  }
}
