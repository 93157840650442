import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import {
  Terminal,
  User,
} from '../../../../../../common/interfaces/prisma.binding';

import { Injectable } from '@angular/core';
import { FetchLastTenLogsAction } from './actions/fetch-last-ten-logs.action';
import { LogsByTerminalQuery } from './queries/logs-by-terminal.query';

export interface LoggingUserTerminalInterface {
  timestamp: string;
  user?: User;
  terminal?: Terminal;
}

export interface LoggingModel {
  logs: LoggingUserTerminalInterface[];
}

@State<LoggingModel>({
  name: 'logging',
  defaults: {
    logs: [],
  },
})
@Injectable()
export class LoggingState {
  constructor(
    private logsByTerminalQuery: LogsByTerminalQuery,
    private logger: NGXLogger,
  ) {}

  @Selector()
  static logs(state: LoggingModel) {
    return state.logs;
  }

  @Action(FetchLastTenLogsAction)
  async getRelays(
    { patchState }: StateContext<LoggingModel>,
    action: FetchLastTenLogsAction,
  ) {
    return new Promise(async (resolve, reject) => {
      this.logsByTerminalQuery
        .watch(
          {
            from: '2025-01-01T0:00:00.644Z',
            to: new Date().toISOString(),
            terminalId: 'ckdolvf9e00je08457uvb0er5', // Terminal Tor
            limit: 15,
          },
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) =>
            resolve(patchState({ logs: data.grayLogs })),
        );
    });
  }
}
