export class FetchTriggerableEntitiesAction {
  static readonly type = '[Triggerable Entities] Fetch Triggerable Entities';
}
export class FetchHassWebhookNamesAction {
  static readonly type = '[Triggerable Entities] Fetch Hass Webhook Names';
}
export class FetchEntityLocationsAction {
  static readonly type = '[Triggerable Entities] Fetch Entity Locations';
}
export class FetchTriggerableEntitiesAuthorizationsAction {
  static readonly type =
    '[Triggerable Entities] Fetch Triggerable Entities Authorizations';
}

export class FetchEspHomeDevices {
  static readonly type = '[Triggerable Entities] Fetch EspHome Devices';
}
