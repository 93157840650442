import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateElectricMeterMutation extends Mutation {
  document = gql`
    mutation updateElectricMeter(
      $id: ID!
      $address: String
      $brandId: ID
      $type: MeterType
      $model: String
      $comment: String
      $hassPrefix: String
      $gpioDevice: ID
    ) {
      updateElectricMeter(
        where: { id: $id }
        data: {
          address: $address
          type: $type
          model: $model
          comment: $comment
          hassPrefix: $hassPrefix
          brand: { connect: { id: $brandId } }
          gpioDevice: { connect: { id: $gpioDevice } }
        }
      ) {
        id
      }
    }
  `;
}
