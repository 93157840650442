import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateElectricMeterMutation extends Mutation {
  document = gql`
    mutation createElectricMeter(
      $address: String!
      $brandId: ID!
      $type: MeterType!
      $comment: String
      $hassPrefix: String
      $gpioDevice: ID
      $model: String
    ) {
      createElectricMeter(
        data: {
          address: $address
          type: $type
          model: $model
          comment: $comment
          hassPrefix: $hassPrefix
          brand: { connect: { id: $brandId } }
          gpioDevice: { connect: { id: $gpioDevice } }
        }
      ) {
        id
      }
    }
  `;
}
