import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class GeneratePowerInvoiceMutation extends Mutation {
  document = gql`
    mutation generatePowerInvoice(
      $monthTimeStamp: DateTime!
      $userId: String
      $draft: Boolean
    ) {
      generatePowerInvoice(
        monthTimeStamp: $monthTimeStamp
        userId: $userId
        draft: $draft
      )
    }
  `;
}
