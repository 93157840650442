import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { HassWebHookName } from '@prisma/client';
import { EspHomeDevice } from '../triggerable-entities.state';

export interface Response {
  espHomeDevices: EspHomeDevice[];
}

@Injectable({
  providedIn: 'root',
})
export class AllEspHomeDevices extends Query<Response> {
  document = gql`
    query espHomeDevices {
      espHomeDevices {
        name
      }
    }
  `;
}
