import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import {
  HassWebHookName,
  TriggerableEntities,
  TriggerableTypes,
  UserEntityRole,
} from '@prisma/client';
import { Select, Store } from '@ngxs/store';
import {
  FetchEntityLocationsAction,
  FetchEspHomeDevices,
  FetchHassWebhookNamesAction,
  FetchTriggerableEntitiesAction,
} from '../../triggerable-entities.actions';
import { combineLatest, Observable } from 'rxjs';
import {
  EspHomeDevice,
  TriggerableEntitiesState,
} from '../../triggerable-entities.state';
import { CreateTriggerableEntityMutation } from '../../mutations/create-triggerable-entity.mutation';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRoute, Router } from '@angular/router';
import { FetchHassWebhooksAction } from '../../../terminal/actions/fetch-hass-webhooks.action';
import { TerminalState } from '../../../terminal/terminal.state';
import { HassWebhook } from '@tooltime/common/interfaces/hassWebhook.bindings';
import { UpdateTriggerableEntityMutation } from '../../mutations/update-triggerable-entity.mutation';
import { OpenDoorMutation } from '../../mutations/open-door.mutation';

@Component({
  selector: 'tt-triggerable-entities-details',
  templateUrl: './triggerable-entities-details.component.html',
  styleUrls: ['./triggerable-entities-details.component.scss'],
})
export class TriggerableEntitiesDetailsComponent implements OnInit {
  triggerableTypesArray = Object.values(TriggerableTypes);
  userEntityRole = Object.values(UserEntityRole);

  selectedType: TriggerableTypes;
  @Select(TriggerableEntitiesState.hassWebhookNames)
  hassWebhookNames$: Observable<HassWebHookName[]>;
  @Select(TerminalState.hassWebhooks)
  hassWebhooks$: Observable<HassWebhook[]>;
  @Select(TriggerableEntitiesState.triggerableEntities)
  triggerableEntities$: Observable<TriggerableEntities[]>;

  @Select(TriggerableEntitiesState.espHomeDevices)
  espHomeDevices$: Observable<EspHomeDevice[]>;

  @Select(TriggerableEntitiesState.entityLocations)
  entityLocations$: Observable<FetchEntityLocationsAction[]>;

  hassWebhookNames: HassWebHookName[];
  hassWebhooks: HassWebhook[];
  filteredHassWebhooks: HassWebhook[];

  private _id: string;
  editMode = false;
  currentTriggerableEntity: TriggerableEntities | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private createTriggerableEntityMutation: CreateTriggerableEntityMutation,
    private updateTriggerableEntityMutation: UpdateTriggerableEntityMutation,
    private openDoorMutation: OpenDoorMutation,
    private logger: NGXLogger,
    private router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  get hassWebHooksToTrigger() {
    return this.triggerableEntitiesForm.get(
      'hassWebHooksToTrigger',
    ) as FormArray;
  }

  addhassWebHooksToTrigger({
    hassWebHookName,
    hassWebHookNameId,
    hassWebhookId = '',
    id,
  }: {
    hassWebHookName: string;
    hassWebHookNameId: string;
    hassWebhookId?: string;
    id?: string | undefined;
  }) {
    const newForm = this.formBuilder.group({
      hassWebHookName: [hassWebHookName, Validators.required],
      hassWebHookNameId: [hassWebHookNameId, Validators.required],
      hassWebhookId: [hassWebhookId],
      id: [id],
    });
    newForm.valueChanges.subscribe((data) => {
      this.filteredHassWebhooks = this.hassWebhooks.filter((hook) =>
        hook.alias.toLowerCase().includes(data.hassWebhookId.toLowerCase()),
      );
    });

    this.hassWebHooksToTrigger.push(newForm);
  }

  triggerableEntitiesForm = this.formBuilder.group({
    type: ['', Validators.required],
    name: ['', Validators.required],
    description: [''],
    entityLocation: ['', Validators.required],
    espHomeName: [''],
    needsSecondAuthFactor: [true],
    needsUserRoles: [[], Validators.required],
    hassWebHooksToTrigger: this.formBuilder.array([]),
    pricePerMinute: 0,
    pausePriceInPercent: 0,
    maintenanceIntervalInHours: 0,
    isEnabled: [true],
  });

  ngOnInit(): void {
    this.store
      .dispatch([
        new FetchHassWebhookNamesAction(),
        new FetchEntityLocationsAction(),
        new FetchHassWebhooksAction(),
        new FetchTriggerableEntitiesAction(),
        new FetchEspHomeDevices(),
      ])
      .subscribe(() => {
        combineLatest([
          this.hassWebhookNames$,
          this.hassWebhooks$,
          this.triggerableEntities$,
          this.route.params,
        ]).subscribe(
          ([
            hassWebHookNames,
            hassWebHooks,
            triggerableEntities,
            routeParams,
          ]) => {
            this.hassWebhookNames = hassWebHookNames;
            this.hassWebhooks = hassWebHooks;
            this.filteredHassWebhooks = hassWebHooks;
            if (routeParams['id'] !== undefined) {
              this.editMode = true;
              this._id = routeParams['id'];
              this.currentTriggerableEntity = triggerableEntities.find(
                (entity) => entity.id === this._id,
              );
              if (this.currentTriggerableEntity) {
                this.populateForm();
                console.log('ID', this.currentTriggerableEntity);
              }
            }
          },
        );
      });
  }

  selectType() {
    console.log('This is the selected type', this.hassWebhookNames);
    this.clearFormArray(this.hassWebHooksToTrigger);
    this.triggerableEntitiesForm.get('needsUserRoles').reset();
    this.hassWebhookNames
      .filter((s) => s.forTriggerableType.indexOf(this.selectedType) > -1)
      .forEach((s) => {
        this.addhassWebHooksToTrigger({
          hassWebHookName: s.name,
          hassWebHookNameId: s.id,
        });
      });
  }

  saveTriggerableEntity() {
    this.createTriggerableEntityMutation
      .mutate({
        name: this.triggerableEntitiesForm.value.name,
        description: this.triggerableEntitiesForm.value.description,
        type: this.triggerableEntitiesForm.value.type,
        pricePerMinute: this.triggerableEntitiesForm.value.pricePerMinute,
        maintenanceIntervalInHours:
          this.triggerableEntitiesForm.value.maintenanceIntervalInHours,
        pausePriceInPercent:
          this.triggerableEntitiesForm.value.pausePriceInPercent,
        needsSecondAuthFactor:
          this.triggerableEntitiesForm.value.needsSecondAuthFactor,
        entityLocationId: this.triggerableEntitiesForm.value.entityLocation,
        espHomeName: this.triggerableEntitiesForm.value.espHomeName,
        needsUserRoles: this.triggerableEntitiesForm.value.needsUserRoles,
        state: 'MACHINE_AVAILABLE',
        isEnabled: this.triggerableEntitiesForm.value.isEnabled,
        hassWebHooksToTrigger:
          this.triggerableEntitiesForm.value.hassWebHooksToTrigger.map(
            (hassWebHook: any) => ({
              hassWebhookId: hassWebHook.hassWebhookId,
              hassWebHookNameId: hassWebHook.hassWebHookNameId,
            }),
          ),
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got create triggerable entity data', data);
          this.router.navigate([
            `/admin/triggerable/edit/${
              (data as any).createTriggerableEntity.id
            }`,
          ]);
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            this.logger.error(message, i),
          );
        },
      );
  }

  updateTriggerableEntity() {
    console.log('Update', this.triggerableEntitiesForm.value);
    this.updateTriggerableEntityMutation
      .mutate({
        id: this.currentTriggerableEntity.id,
        name: this.triggerableEntitiesForm.value.name,
        description: this.triggerableEntitiesForm.value.description,
        type: this.triggerableEntitiesForm.value.type,
        pricePerMinute: this.triggerableEntitiesForm.value.pricePerMinute,
        maintenanceIntervalInHours:
          this.triggerableEntitiesForm.value.maintenanceIntervalInHours,
        pausePriceInPercent:
          this.triggerableEntitiesForm.value.pausePriceInPercent,
        needsSecondAuthFactor:
          this.triggerableEntitiesForm.value.needsSecondAuthFactor,
        entityLocationId: this.triggerableEntitiesForm.value.entityLocation,
        espHomeName: this.triggerableEntitiesForm.value.espHomeName,
        needsUserRoles: this.triggerableEntitiesForm.value.needsUserRoles,
        isEnabled: this.triggerableEntitiesForm.value.isEnabled,
        hassWebHooksToTrigger:
          this.triggerableEntitiesForm.value.hassWebHooksToTrigger.map(
            (hassWebHook: any) => ({
              hassWebhookId: hassWebHook.hassWebhookId,
              hassWebHookNameId: hassWebHook.hassWebHookNameId,
              id: hassWebHook.id,
            }),
          ),
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got update triggerable entity data', data);
          this.router.navigate([
            `/admin/triggerable/edit/${
              (data as any).updateTriggerableEntity.id
            }`,
          ]);
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            this.logger.error(message, i),
          );
        },
      );
  }

  filteredUserRoles = () => {
    return this.userEntityRole.filter(
      (role) =>
        role.startsWith(this.selectedType.split('_')[0]) || role === 'ADMIN',
    );
  };
  private clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  displayFn(user: string): string {
    return user && user ? user : '';
  }

  onBlur() {
    this.filteredHassWebhooks = this.hassWebhooks;
  }

  populateForm() {
    this.triggerableEntitiesForm.patchValue({
      type: this.currentTriggerableEntity.type,
      name: this.currentTriggerableEntity.name,
      description: this.currentTriggerableEntity.description,
      entityLocation: this.currentTriggerableEntity.entityLocationId,
      espHomeName: this.currentTriggerableEntity.espHomeName,
      pricePerMinute: this.currentTriggerableEntity.pricePerMinute,
      maintenanceIntervalInHours:
        this.currentTriggerableEntity.maintenanceIntervalInHours,
      isEnabled: this.currentTriggerableEntity.isEnabled,
      pausePriceInPercent: this.currentTriggerableEntity.pausePriceInPercent,
      needsSecondAuthFactor:
        this.currentTriggerableEntity.needsSecondAuthFactor,
      needsUserRoles: this.currentTriggerableEntity.needsUserRoles as null,
    });
    this.selectedType = this.currentTriggerableEntity.type;
    this.clearFormArray(this.hassWebHooksToTrigger);
    // @ts-ignore
    this.currentTriggerableEntity.hassWebHooksToTrigger.forEach((hook) => {
      this.addhassWebHooksToTrigger({
        hassWebHookName: this.hassWebhookNames.find(
          (h) => h.id === hook.hassWebHookNameId,
        ).name,
        hassWebHookNameId: hook.hassWebHookNameId,
        hassWebhookId: hook.hassWebhookId,
        id: hook.id,
      });
    });
    // fill the webhooks with eventually empty trigger names
    this.hassWebhookNames
      .filter((s) => s.forTriggerableType.indexOf(this.selectedType) > -1)
      .filter((s) => {
        return !this.hassWebHooksToTrigger.value.find(
          (h) => h.hassWebHookNameId === s.id,
        );
      })
      .forEach((s) => {
        this.addhassWebHooksToTrigger({
          hassWebHookName: s.name,
          hassWebHookNameId: s.id,
        });
      });
  }

  triggerOpenHook() {
    this.openDoorMutation
      .mutate({
        triggerableEntityId: this.currentTriggerableEntity.id,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got open Door Mutation', data);
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            this.logger.error(message, i),
          );
        },
      );
  }
}
