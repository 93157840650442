import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import {
  TriggerableEntities,
  HassWebHookName,
  TriggerableTypes,
  EntityLocation,
} from '@prisma/client';

import { AuthService } from '../../shared/services/auth.service';

import { Injectable } from '@angular/core';

import {
  FetchEntityLocationsAction,
  FetchEspHomeDevices,
  FetchHassWebhookNamesAction,
  FetchTriggerableEntitiesAction,
  FetchTriggerableEntitiesAuthorizationsAction,
} from './triggerable-entities.actions';
import { AllHassWebhookNames } from './queries/all-hass-webhook-names.query';
import { AllEntityLocations } from './queries/all-entity-locations.query';
import { AllTriggerableEntities } from './queries/all-triggerable-entities.query';
import { AllTriggerableEntitiesAuthorizations } from './queries/all-triggerable-etities-authorizations.query';
import { AllEspHomeDevices } from './queries/all-esp-home-devices.query';

interface Connection {
  type: string;
  value: string;
}

export interface EspHomeDevice {
  area_id: string;
  configuration_url: string;
  config_entries: string[];
  connections: Connection[];
  created_at: number;
  disabled_by: string | null;
  entry_type: string | null;
  hw_version: string | null;
  id: string;
  identifiers: string[];
  labels: string[];
  manufacturer: string;
  model: string;
  model_id: string | null;
  modified_at: number;
  name_by_user: string | null;
  name: string;
  primary_config_entry: string;
  serial_number: string | null;
  sw_version: string;
  via_device_id: string | null;
}

export interface TriggerableEntitiesStateModel {
  triggerableEntities: TriggerableEntities[];
  hassWebhookNames: HassWebHookName[];
  entityLocations: EntityLocation[];
  triggerableEntitiesAuthorizations: AllTriggerableEntitiesAuthorizations[];
  esphomeDevices: EspHomeDevice[];
}

@State<TriggerableEntitiesStateModel>({
  name: 'triggerableEntities',
  defaults: {
    triggerableEntities: [],
    hassWebhookNames: [],
    entityLocations: [],
    triggerableEntitiesAuthorizations: [],
    esphomeDevices: [],
  },
})
@Injectable()
export class TriggerableEntitiesState {
  constructor(
    private authServie: AuthService,
    private allEspHomeDevices: AllEspHomeDevices,
    private allHassWebhookNames: AllHassWebhookNames,
    private allEntityLocations: AllEntityLocations,
    private allTriggerableEntities: AllTriggerableEntities,
    private allTriggerableEntitiesAuthorizations: AllTriggerableEntitiesAuthorizations,
  ) {}

  @Selector()
  static hassWebhookNames(state: TriggerableEntitiesStateModel) {
    return state.hassWebhookNames;
  }
  @Selector()
  static espHomeDevices(state: TriggerableEntitiesStateModel) {
    return state.esphomeDevices;
  }
  @Selector()
  static entityLocations(state: TriggerableEntitiesStateModel) {
    return state.entityLocations;
  }

  @Selector()
  static triggerableEntities(state: TriggerableEntitiesStateModel) {
    return state.triggerableEntities;
  }
  @Selector()
  static triggerableEntitiesAuthorizations(
    state: TriggerableEntitiesStateModel,
  ) {
    return state.triggerableEntitiesAuthorizations;
  }
  // Filtered Selector (no @Selector() decorator needed)
  static hassWebhookNamesByTriggerableType(type: TriggerableTypes | undefined) {
    return createSelector(
      [TriggerableEntitiesState],
      (state: TriggerableEntitiesStateModel) => {
        return state.hassWebhookNames.filter(
          (s) => s.forTriggerableType.indexOf(type) > -1,
        );
      },
    );
  }
  @Action(FetchHassWebhookNamesAction)
  async getHassWebhookNames(
    { patchState }: StateContext<TriggerableEntitiesStateModel>,
    action: FetchHassWebhookNamesAction,
  ) {
    return new Promise(async (resolve, reject) => {
      this.allHassWebhookNames
        .watch(
          {},
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) =>
            resolve(patchState({ hassWebhookNames: data.hassWebHookNames })),
        );
    });
  }
  @Action(FetchEntityLocationsAction)
  async getEntityLocations(
    { patchState }: StateContext<TriggerableEntitiesStateModel>,
    action: FetchEntityLocationsAction,
  ) {
    return new Promise(async (resolve, reject) => {
      this.allEntityLocations
        .watch(
          {},
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) =>
            resolve(patchState({ entityLocations: data.entityLocations })),
        );
    });
  }
  @Action(FetchTriggerableEntitiesAction)
  async getTriggerableEntities(
    { patchState }: StateContext<TriggerableEntitiesStateModel>,
    action: FetchEntityLocationsAction,
  ) {
    return new Promise(async (resolve, reject) => {
      this.allTriggerableEntities
        .watch(
          {},
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) =>
            resolve(
              patchState({ triggerableEntities: data.triggerableEntities }),
            ),
        );
    });
  }
  @Action(FetchTriggerableEntitiesAuthorizationsAction)
  async getTriggerableEntitiesAuthorizations(
    { patchState }: StateContext<TriggerableEntitiesStateModel>,
    action: FetchTriggerableEntitiesAuthorizationsAction,
  ) {
    return new Promise(async (resolve, reject) => {
      this.allTriggerableEntitiesAuthorizations
        .watch(
          {},
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) =>
            resolve(
              patchState({
                triggerableEntitiesAuthorizations:
                  data.triggerableEntitiesAuthorizations,
              }),
            ),
        );
    });
  }
  @Action(FetchEspHomeDevices)
  async getEspHomeDevices(
    { patchState }: StateContext<TriggerableEntitiesStateModel>,
    action: FetchEspHomeDevices,
  ) {
    return new Promise(async (resolve, reject) => {
      this.allEspHomeDevices
        .watch(
          {},
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) => {
            return resolve(
              patchState({
                esphomeDevices: data.espHomeDevices,
              }),
            );
          },
        );
    });
  }
}
