import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import {
  ElectricMeter,
  ElectricMeterManufacturer,
  GPIODevice,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { FetchElectricMeterManufacturersAction } from '../../actions/fetch-electric-meter-manufacturers.action';
import { FetchElectricMetersAction } from '../../actions/fetch-electric-meters.action';
import { ElectricMeterState } from '../../electric-meter.state';
import { CreateElectricMeterMutation } from '../../mutations/create-electric-meter.mutation';
import { UpdateElectricMeterMutation } from '../../mutations/update-electric-meter.mutation';
import { AllElectricMetersQuery } from '../../queries/all-electric-meters.query';
import { GpioDevicesState } from '../../../gpio-devices/gpio-devices.state';
import { FetchGpioDevicesAction } from '../../../gpio-devices/actions/fetch-gpio-devices.action';

/**
 * validator that checks if address of meter is already in use
 * @param electricMeters
 */
export function addressValidator(electricMeters: ElectricMeter[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let forbidden = true;
    if (control.value) {
      const gpioDeviceID = control.parent.get('gpioDevice').value;
      forbidden = electricMeters.some((electricMeter) => {
        return (
          electricMeter.address === control.value.toString() &&
          gpioDeviceID === electricMeter?.gpioDevice?.id
        );
      });
    }
    return forbidden ? { addressInUse: { value: control.value } } : null;
  };
}

@Component({
  selector: 'tt-electric-meters-detail',
  templateUrl: './electric-meters-detail.component.html',
  styleUrls: ['./electric-meters-detail.component.scss'],
})
export class ElectricMetersDetailComponent implements OnInit {
  @Select(ElectricMeterState.electricMeters) electricMeters$: Observable<
    ElectricMeter[]
  >;
  @Select(ElectricMeterState.electricMeterManufacturers)
  electricMeterManufacturers$: Observable<ElectricMeterManufacturer[]>;
  @Select(GpioDevicesState.gpioDevices)
  gpioDevices$: Observable<GPIODevice[]>;

  modBusGpioDevices: GPIODevice[] = [];
  editMode = false;
  electricMeterForm: UntypedFormGroup;
  electricMeters: ElectricMeter[];
  meterType = [
    { name: '1 Phase', type: 'ONE_PHASE' },
    { name: '3 Phasen', type: 'THREE_PHASE' },
  ];

  meterModels = ['sdm72', 'sdm120', 'sdm630'];
  private _id: string;

  constructor(
    private allElectricMetersQuery: AllElectricMetersQuery,
    private route: ActivatedRoute,
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private createElectricMeterMutation: CreateElectricMeterMutation,
    private logger: NGXLogger,
    private router: Router,
    private snackBar: MatSnackBar,
    private updateElectricMeter: UpdateElectricMeterMutation,
  ) {}

  get address() {
    return this.electricMeterForm.get('address');
  }

  ngOnInit() {
    this.store.dispatch([
      new FetchElectricMetersAction(),
      new FetchElectricMeterManufacturersAction(),
      new FetchGpioDevicesAction(),
    ]);

    this.electricMeters$.subscribe((electricMeters) => {
      this.electricMeters = electricMeters;
      this.electricMeterForm = this.formBuilder.group({
        address: [
          '',
          [Validators.required, addressValidator(this.electricMeters)],
        ],
        gpioDevice: ['', [Validators.required]],
        brand: ['', Validators.required],
        comment: [''],
        hassPrefix: [''],
        type: ['', Validators.required],
        model: ['', Validators.required],
      });
      this.route.params.subscribe((params) => {
        if (params['id'] !== undefined) {
          this.editMode = true;
          this.electricMeterForm.get('address').disable();
          this._id = params['id'];
          this._populateForm(this._id);
        }
      });
    });
    this.gpioDevices$.subscribe((gpioDevices) => {
      this.modBusGpioDevices = gpioDevices.filter(
        (gpioDevice) => gpioDevice.hasModBusDevice,
      );
    });
  }

  createMeter() {
    this.createElectricMeterMutation
      .mutate({
        address: this.electricMeterForm.value.address.toString(),
        brandId: this.electricMeterForm.value.brand.toString(),
        comment: this.electricMeterForm.value?.comment?.toString(),
        hassPrefix: this.electricMeterForm.value?.hassPrefix?.toString(),
        model: this.electricMeterForm.value?.model?.toString(),
        type: this.electricMeterForm.value.type.toString(),
        gpioDevice: this.electricMeterForm.value.gpioDevice.toString(),
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got create electric meter data', data);
          this.router.navigate([
            `/admin/electric-meters/edit/${
              (data as any).createElectricMeter.id
            }`,
          ]);
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            this.logger.error(message, i),
          );
        },
      );
  }

  updateMeter() {
    this.updateElectricMeter
      .mutate({
        id: this._id,
        brandId: this.electricMeterForm.value.brand.toString(),
        comment: this.electricMeterForm.value?.comment?.toString(),
        hassPrefix: this.electricMeterForm.value?.hassPrefix?.toString(),
        type: this.electricMeterForm.value.type.toString(),
        gpioDevice: this.electricMeterForm.value.gpioDevice.toString(),
        model: this.electricMeterForm.value.model.toString(),
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got update electric meter data', data);
          this.snackBar.open('Zähler erfolgreich geupdated', '', {
            duration: 2000,
          });
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            this.logger.error(message, i),
          );
        },
      );
  }

  private _populateForm(id: string) {
    const currentMeter = this.electricMeters.find((meter) => meter.id === id);
    if (currentMeter) {
      this.electricMeterForm.patchValue({
        address: currentMeter.address,
        type: currentMeter.type,
        comment: currentMeter.comment,
        hassPrefix: currentMeter.hassPrefix,
        brand: currentMeter.brand.id,
        model: currentMeter?.model,
        gpioDevice: currentMeter?.gpioDevice?.id,
      });
    }
  }
}
