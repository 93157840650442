import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import {
  ElectricMeter,
  ElectricMeterManufacturer,
  MeterReading,
} from '../../../../../../common/interfaces/prisma.binding';
import { AuthService } from '../../shared/services/auth.service';
import { FetchElectricMeterManufacturersAction } from './actions/fetch-electric-meter-manufacturers.action';
import { FetchElectricMetersAction } from './actions/fetch-electric-meters.action';
import { FetchMeterReadingAction } from './actions/fetch-meter-reading.action';
import { CreateElectricMeterMutation } from './mutations/create-electric-meter.mutation';
import { AllElectricMeterManufacturesQuery } from './queries/all-electric-meter-manufactures.query';
import { AllElectricMetersQuery } from './queries/all-electric-meters.query';
import { GetMeterReadingsByKeycloakidQuery } from './queries/get-meter-readings-by-keycloakid.query';
import { Injectable } from '@angular/core';

export interface ElectricMeterStateModel {
  electricMeters: ElectricMeter[];
  meterReadings: MeterReading[];
  electricMeterManufacturers: ElectricMeterManufacturer[];
}

@State<ElectricMeterStateModel>({
  name: 'electricMeters',
  defaults: {
    electricMeters: [],
    electricMeterManufacturers: [],
    meterReadings: [],
  },
})
@Injectable()
export class ElectricMeterState {
  constructor(
    private allElectricMetersQuery: AllElectricMetersQuery,
    private allElectricMeterManufacturesQuery: AllElectricMeterManufacturesQuery,
    private authService: AuthService,
    private meterByKeycloadkIdQuery: GetMeterReadingsByKeycloakidQuery,
    private createElectricMeterMutation: CreateElectricMeterMutation,
    private logger: NGXLogger,
  ) {}

  @Selector()
  static electricMeters(state: ElectricMeterStateModel) {
    return state.electricMeters;
  }

  @Selector()
  static electricMeterManufacturers(state: ElectricMeterStateModel) {
    return state.electricMeterManufacturers;
  }

  @Selector()
  static meterReadings(state: ElectricMeterStateModel) {
    return state.meterReadings;
  }

  @Action(FetchElectricMetersAction)
  async getElectricMeters(
    { patchState }: StateContext<ElectricMeterStateModel>,
    action: FetchElectricMetersAction,
  ) {
    return new Promise(async (resolve, reject) => {
      this.allElectricMetersQuery
        .fetch(
          {},
          {
            fetchPolicy: 'network-only',
          },
        )
        .subscribe(({ data, loading }: { data: any; loading: boolean }) =>
          resolve(patchState({ electricMeters: data.electricMeters })),
        );
    });
  }

  @Action(FetchElectricMeterManufacturersAction)
  async getElectricMeterManufacturers(
    { patchState }: StateContext<ElectricMeterStateModel>,
    action: FetchElectricMeterManufacturersAction,
  ) {
    return new Promise(async (resolve, reject) => {
      this.allElectricMeterManufacturesQuery
        .watch(
          {},
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) =>
            resolve(
              patchState({
                electricMeterManufacturers: data.electricMeterManufacturers,
              }),
            ),
        );
    });
  }

  @Action(FetchMeterReadingAction)
  async getMeterReadingsByKeycloakid(
    { patchState }: StateContext<ElectricMeterStateModel>,
    action: FetchMeterReadingAction,
  ) {
    return new Promise(async (resolve, reject) => {
      this.meterByKeycloadkIdQuery
        .watch(
          { keycloakID: action.kcId },
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(
          ({ data, loading }: { data: any; loading: boolean }) =>
            resolve(patchState({ meterReadings: data.meterReadings })),
        );
    });
  }
}
