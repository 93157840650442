import { Component, HostListener, OnInit } from '@angular/core';
import {
  FetchTriggerableEntitiesAction,
  FetchTriggerableEntitiesAuthorizationsAction,
} from '../../triggerable-entities.actions';
import { FetchUserAction } from '../../../user/actions/fetch-user.action';
import { Select, Store } from '@ngxs/store';
import { UserState } from '../../../user/user.state';
import { combineLatest, Observable } from 'rxjs';
import { User } from '@tooltime/common/interfaces/prisma.binding';
import { TriggerableEntitiesState } from '../../triggerable-entities.state';
import {
  TriggerableEntities,
  TriggerableEntitiesAuthorizations,
  TriggerableTypes,
  UserEntityRole,
} from '@prisma/client';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select';
import { SelectionModel } from '@angular/cdk/collections';
import { CanComponentDeactivate } from '../../../../shared/guards/can-component-deactivate.guard';
import { CreateTriggerableEntitiesAuthorizationsMutation } from '../../mutations/create-triggerable-entities-authorizations.mutation';
import { NGXLogger } from 'ngx-logger';
import { BatchUpdateTriggerableEntitiesAuthorizationsMutation } from '../../mutations/batch-update-triggerable-entities-authorizations.mutation';
import { Router } from '@angular/router';

@Component({
  selector: 'tt-triggerable-entities-authorization',
  templateUrl: './triggerable-entities-authorization.component.html',
  styleUrls: ['./triggerable-entities-authorization.component.scss'],
})
export class TriggerableEntitiesAuthorizationComponent
  implements OnInit, CanComponentDeactivate
{
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.matrixIsPristine) {
      $event.returnValue = true; // Chrome requires returnValue to be set
    }
  }

  userEntityRole = Object.values(UserEntityRole);
  triggerableTypesArray = Object.values(TriggerableTypes);

  displayedColumns = [];
  columns = [];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<User>(true, []);

  matrixData: Map<string, Map<string, string[]>> = new Map<
    string,
    Map<string, string[]>
  >();
  @Select(UserState.users) users$: Observable<User[]>;
  @Select(TriggerableEntitiesState.triggerableEntities) entities$: Observable<
    TriggerableEntities[]
  >;
  @Select(TriggerableEntitiesState.triggerableEntitiesAuthorizations)
  triggerableEntitiesAuthorizations$: Observable<
    TriggerableEntitiesAuthorizations[]
  >;
  filteredTriggerableEntities: TriggerableEntities[] = [];
  batchSelectedTriggerableEntityIds: string[];
  batchSelectedType: TriggerableTypes;
  batchSelectedUserRoles: UserEntityRole[] = [];
  batchOverwriteRoles = false;

  constructor(
    private store: Store,
    private createTriggerableEntitiesAuthorizationsMutation: CreateTriggerableEntitiesAuthorizationsMutation,
    private batchUpdateTriggerableEntitiesAuthorizationsMutation: BatchUpdateTriggerableEntitiesAuthorizationsMutation,
    private logger: NGXLogger,
    private router: Router,
  ) {}

  entities: TriggerableEntities[] = [];
  triggerableEntitiesAuthorizations: TriggerableEntitiesAuthorizations[] = [];
  matrixIsPristine = true;

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    // Check if there are any unsaved changes or any other condition
    // Return true if the user can leave the page, false otherwise

    return this.matrixIsPristine;
  }

  ngOnInit(): void {
    this.store
      .dispatch([
        new FetchTriggerableEntitiesAuthorizationsAction(),
        new FetchTriggerableEntitiesAction(),
        new FetchUserAction(),
      ])
      .subscribe(() => {
        combineLatest([
          this.users$,
          this.entities$,
          this.triggerableEntitiesAuthorizations$,
        ]).subscribe(([user, entities, triggerableEntitiesAuthorizations]) => {
          this.triggerableEntitiesAuthorizations =
            triggerableEntitiesAuthorizations;
          this.columns.push({
            columnDef: 'selection',
            header: 'selection',
            cell: (user: User) => user,
          }); // Add a column for the user
          this.columns.push({
            columnDef: 'user',
            header: 'User',
            cell: (user: User) => user,
          });
          entities.forEach((entity) => {
            this.columns.push({
              columnDef: entity.id,
              type: entity.type,
              header: `${
                entity.name
              } - Needed Roles: ${entity.needsUserRoles.join(', ')}`,
              cell: (entity: TriggerableEntities) => entity,
            });
          });
          this.displayedColumns = this.columns.map((c) => c.columnDef);
          this.dataSource = new MatTableDataSource(user);
          this.entities = entities;
        });
      });
  }

  filteredUserEntityRole = (type: UserEntityRole) =>
    this.userEntityRole.filter(
      (role) => role.startsWith(type) || role === 'ADMIN',
    );

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selectChange(
    event: MatSelectChange,
    triggerableEntityId: string,
    user: User,
  ) {
    this.matrixIsPristine = false;
    // We have data for the user
    if (this.matrixData.has(user.keycloakID)) {
      this.matrixData
        .get(user.keycloakID)
        .set(triggerableEntityId, event.value);
    } else {
      // don't have data for the user
      const tempMap: Map<string, string[]> = new Map();
      tempMap.set(triggerableEntityId, event.value as string[]);
      this.matrixData.set(user.keycloakID, tempMap);
    }
    console.log(event, triggerableEntityId, user);
    console.log(this.matrixData);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: User): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.username
    }`;
  }

  saveMatrix() {
    this.matrixIsPristine = true;
    const data = JSON.stringify(this.mapToObject(this.matrixData));
    this.createTriggerableEntitiesAuthorizationsMutation
      .mutate({
        data,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got create triggerable entity auth  data', data);
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            this.logger.error(message, i),
          );
        },
      );
  }

  getPreselectRoles(user: User, entityId: string) {
    const triggerableEntitiesAuthorization =
      this.triggerableEntitiesAuthorizations.find(
        (auth) =>
          auth.userId === user.keycloakID &&
          auth.triggerableEntityId === entityId,
      );
    if (triggerableEntitiesAuthorization) {
      return triggerableEntitiesAuthorization.userRoles;
    }
    return undefined;
  }

  private mapToObject(map: Map<any, any>): any {
    const out = {};
    map.forEach((value, key) => {
      if (value instanceof Map) {
        out[key] = this.mapToObject(value); // Recursively convert nested maps
      } else {
        out[key] = value;
      }
    });
    return out;
  }

  batchSelectType($event: MatSelectChange) {
    this.filteredTriggerableEntities = this.entities.filter(
      (entity) => entity.type === $event.value,
    );
    this.batchSelectedType = $event.value;
  }

  doBatchOperations() {
    console.log(
      this.batchSelectedTriggerableEntityIds,
      this.batchSelectedUserRoles,
      this.selection.selected.map((user) => user.keycloakID),
    );
    this.batchUpdateTriggerableEntitiesAuthorizationsMutation
      .mutate({
        triggerableEntityIds: this.batchSelectedTriggerableEntityIds,
        userIds: this.selection.selected.map((user) => user.keycloakID),
        userRoles: this.batchSelectedUserRoles,
        overwriteRoles: this.batchOverwriteRoles,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got batch update entity auth  data', data);
          this.store.dispatch([
            new FetchTriggerableEntitiesAuthorizationsAction(),
          ]);
          this.router.navigate([`/admin/triggerable-auth`]);
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            this.logger.error(message, i),
          );
        },
      );
  }
}
