import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateTriggerableEntityMutation extends Mutation {
  document = gql`
    mutation createTriggerableEntity(
      $name: String!
      $description: String
      $type: TriggerableTypes!
      $entityLocationId: String!
      $needsSecondAuthFactor: Boolean
      $needsUserRoles: [UserEntityRole!]
      $hassWebHooksToTrigger: [CreateHassWebHooksInput!]
      $pricePerMinute: Float
      $pausePriceInPercent: Float
      $maintenanceIntervalInHours: Int
      $state: TriggerableEntitiesState
      $isEnabled: Boolean
      $espHomeName: String
    ) {
      createTriggerableEntity(
        input: {
          name: $name
          description: $description
          type: $type
          entityLocationId: $entityLocationId
          espHomeName: $espHomeName
          needsSecondAuthFactor: $needsSecondAuthFactor
          needsUserRoles: $needsUserRoles
          hassWebHooksToTrigger: $hassWebHooksToTrigger
          pricePerMinute: $pricePerMinute
          pausePriceInPercent: $pausePriceInPercent
          maintenanceIntervalInHours: $maintenanceIntervalInHours
          state: $state
          isEnabled: $isEnabled
        }
      ) {
        id
      }
    }
  `;
}
